<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">巴林右旗牧业统计</div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <div class="left_box">
          <!-- <div class="title"><span>养殖类</span></div> -->

          <div class="title flex_start">
            <div class="w_110 main_color p_10 border_box fs_18 fw_bold">
              <span>养殖类</span>
            </div>

            <div class="w_80 h_40 mon_text lh_25 color_fff fs_16 main_color mt_10">
              (ᠲᠡᠵᠢᠭᠡᠨ ᠦᠷᠡᠵᠢᠭᠦᠯᠬᠦ ᠲᠥᠷᠦᠯ)
            </div>
          </div>

          <bing-tu :value="data1" />
        </div>

        <div class="left_box">
          <!-- <div class="title"><span>养殖户规模统计</span></div> -->
          <div class="title flex_start">
            <div class="w_110 main_color p_10 border_box fs_18 fw_bold">
              <span>养殖户规模统计</span>
            </div>

            <div class="w_100 h_40 mon_text lh_25 color_fff fs_16 main_color mt_10 ml_50">
              (ᠲᠡᠵᠢᠭᠡᠨ ᠦᠷᠡᠵᠢᠭᠦᠯᠬᠦ ᠡᠷᠦᠬᠡ᠎ᠶᠢᠨ ᠬᠡᠪᠴᠢᠶᠡᠨ᠎ᠦ ᠪᠦᠷᠢᠳᠭᠡᠯ)
            </div>
          </div>
          
          <kong-xin-bing-tu :value="data2" />
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="map_box">
          <!-- <da-ban-map /> -->
          <ba-lin-you-qi-map class="map" />

          <!-- <div class="map">
            <img src="../../../../assets/images/ba-lin-you-qi/map.png" alt="">
          </div> -->
        </div>

        <div class="context_box">
          巴林右旗位于内蒙古自治区东部，赤峰市北部，驻地大板镇，
          地处西拉沐沦河北岸，全旗总面积10256平方公里。
          <!-- 下辖4个苏木、5个镇，截至2023年年初全旗户籍总人口18.64万人。人口密度为18人／平方公里。 -->
        </div>

        <div class="bottom_box mt_0">
          <!-- <div class="title"><span>智能化</span></div> -->
          <div class="title flex_start">
            <div class="w_110 main_color p_10 border_box fs_18 fw_bold">
              <span>智能化</span>
            </div>

            <div class="w_100 h_40 mon_text lh_25 color_fff fs_16 main_color mt_10 ml_230">
              (ᠣᠶᠤᠨᠴᠢᠯᠠᠬᠤ)
            </div>
          </div>

          <ban-bing-tu :value="data3" />
        </div>
      </div> 

      <!-- 3 -->
      <div class="content_item_box">
        <div class="su_mu_list">
          <!-- <div class="title"><span>管辖苏木、镇</span></div> -->
          <div class="title flex_start">
            <div class="w_110 main_color p_10 border_box fs_18 fw_bold">
              <span>管辖苏木、镇</span>
            </div>

            <div class="w_100 h_40 mon_text lh_25 color_fff fs_16 main_color mt_10 ml_260">
              (ᠬᠠᠷᠢᠶ᠎ᠠ ᠰᠤᠮᠤ᠂ ᠪᠠᠯᠭᠠᠰᠤ)
            </div>
          </div>

          <div class="list_header">
            <div class="num color_green">序号</div>
            <div class="village_name color_green">苏木（镇）名称</div>
          </div>
          
          <div class="list_box">
            <div class="list_item" v-for="(item, index) in list" :key="index">
              <span v-if="index < 9">0{{ index+1 }}</span> 
              <span v-else>{{ index+1 }}</span> 

              <div class="name" :class="{main_color: item.name == '大板镇'}" @click="goToVillagePage(item.name)">{{ item.name }}</div> 
              <!-- <div class="value">{{ item.value }}</div>  -->
            </div>
          </div>

        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import ChinaMap from '../maps/chinaMap.vue'
  import BingTu from '../charts/bing-tu.vue'
  import DaBanMap from '../maps/daBanMap.vue'
  import BanBingTu from '../charts/ban-bing-tu.vue'
  import KongXinBingTu from '../charts/kong-xin-bing-tu.vue'

  export default {
    name: 'showPage1',
    components: { BaLinYouQiMap, BingTu, DaBanMap, ChinaMap, BanBingTu, KongXinBingTu },
    data() {
      return {
        activeIndex: 0,
        mapName: '巴林右旗大板镇',

        data1: {},
        data2: {},
        data3: {},        

        list: [
          {name: "索博日嘎镇", value: 23},
          {name: "幸福之路苏木", value: 524},
          {name: "巴彦琥硕镇", value: 14},
          {name: "查干沐沦苏木", value: 150},
          {name: "大板镇", value: 75},
          {name: "大板煤电化基地", value: 13},
          {name: "巴彦塔拉苏木", value: 83},
          {name: "查干诺尔镇", value: 11},
          {name: "宝日勿苏镇", value: 19},
          {name: "西拉沐沦苏木", value: 15}   
        ],
        
      }
    },
    mounted() {
      setTimeout(() => {
        this.initChart()
      }, 100)  

      this.$bus.$on('setData', val => {
        if (val.mapName !== '大板镇') {
          // this.data1 = val.zhong_zhi_zhong_lei
          // this.data2 = val.yang_zhi_lei
          // this.data3 = val.chan_liang

          for (let i in this.data1.data) {
            if (this.data1.data[i].value > 4000) {
              this.data1.data[i].value -= 987
            } else {
              this.data1.data[i].value += 438
            }
          }

          for (let i in this.data2.data) {
            if (this.data2.data[i].value > 4000) {
              this.data2.data[i].value -= 83
            } else {
              this.data2.data[i].value += 98
            }
          }

        } else {
          this.initChart()
        }
      })
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: '养殖类',
          unit: '头',
          data: [
            {"name": "牛", "value": 23570, "unit": '头'},
            {"name": "马", "value": 12432, "unit": '头'},
            {"name": "绵羊", "value": 12521, "unit": '头'},
            {"name": "山羊", "value": 13676, "unit": '头'},
            {"name": "驴", "value": 8243, "unit": '头'},
            {"name": "骆驼", "value": 3451, "unit": '头'}
          ]
        }

        this.data2 = {
          title: '养殖户规模统计',
          unit: '户',
          data: [
            {"name": "<50头", "value": 750, "unit": ''},
            {"name": "50-100头", "value": 326, "unit": ''},
            {"name": "100-150头", "value": 168, "unit": ''},
            {"name": "150-200头", "value": 368, "unit": ''},
            {"name": "200-250头", "value": 88, "unit": ''},
            {"name": ">200头", "value": 68, "unit": ''},
          ]
        }

        this.data3 = {
          title: '智能化统计',
          unit: '件',
          data: [
            {"name": "监控", "value": 570, "unit": ''},
            {"name": "开关", "value": 243, "unit": ''},
            {"name": "搅拌机", "value": 432, "unit": ''},
            {"name": "饲草机", "value": 521, "unit": ''},
            {"name": "清粪机", "value": 176, "unit": ''},
            {"name": "开关", "value": 243, "unit": ''}
          ]
        }
      },

      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      // 前往 巴林右旗 - 苏木（镇）- 牧业统计页面
      goToVillagePage(val) {
        this.$router.push({
          path: "/bigscreen/villageAnimal" + val
        })
      },

      // 跳转到 人口统计 图表详情
      goToPersonStateDetail() {
        let title = `${this.mapName}人口统计`

        this.$router.push({
          path: "/bigscreen/personState" + title
        })
      },

      // 跳转到 机械化统计 图表详情
      goToMashineStateDetail() {
        let title = `${this.mapName}机械化统计`

        this.$router.push({
          path: "/bigscreen/mashineState" + title
        })
      }
    }
  }
</script>

<style scoped lang="less">
  @import url('../../../../assets/css/bigscreen.less');
  .activeColor { color: #2366fc !important; }

</style>